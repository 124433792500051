<template>
    <div class="cooperative-goods white-back flex-1">
        <el-input placeholder="商品名称" v-model="keyword">

            <el-button type="primary" slot="append" @click="getList">查询</el-button>
        </el-input>
        <div class="all-goods">
            <div class="product pointer" v-for="item in list" :key="item.id" @click="detail(item.id,item)" >
                <div v-if="item.goods_sku.length>0">
                    <div class="img">
                        <el-image :src="domain+item.main_image" class="error-img">
                            <div slot="error" class="image-slot">
                                <div slot="error" class="image-slot">
                                    <img src="@/assets/img/logo.png" alt="" class="logo-img"/>
                                </div>
                            </div>
                        </el-image>
                    </div>
                    <p class="line-2 name">
                        <span v-if="item.brand">【{{item.brand.name}}】</span>
                        {{item.name}}</p>
                </div>
                <div v-if="!item.goods_sku.id&&item.goods_sku.length>0">
                    <p class="r-f price" v-if="item.goods_sku[0].group_price">
                        成交价:￥{{item.goods_sku[0].group_price}}
                    </p>
                    <p class="r-f price" v-else>
                        市场价:￥{{item.goods_sku[0].market_price}}
                    </p>

                    <p class="g-f fs-12 cost_price">挂网价：￥{{item.goods_sku[0].cost_price}}</p>
                </div>

            </div>
        </div>
        <div class="flex-end page" >
            <Pagination :size="10" :total="total" @changePage="changePage"></Pagination>
        </div>
        <el-empty :image="emptyImg" :image-size="468" description="暂时没有内容哦~" class="empty" v-if="total==0"/>
    </div>
</template>

<script>
    import Product from "@/components/Product";
    import Pagination from "@/components/Pagination";
    import {gCooperative_goodsa} from '@/request/api';
    import {domain} from "@/request/utils";
    import emptyImg from '@/assets/img/no_index.png';

    export default {
        name: "index",
        components: {
            Product, Pagination
        },

        data() {
            return {
                list: [],
                domain,
                keyword: '',
                total: 0,
                page: 1,
                emptyImg
            }
        },
        methods: {
            async getList() {
                let enterprise_id = localStorage.getItem('enterprise_id');
                let params = {page: this.page, enterprise_id, keyword: this.keyword}
                let res = await gCooperative_goodsa(params);
                this.list = res.data.list;
                this.total = res.data.count;
            },
            detail(id, item) {
                // if (item.is_sale == 2) {
                //     return this.$message.error('该产品已下架，如需要请联系客服,谢谢您的支持！')
                // }
                const url = this.$router.resolve({path: '/productDetail', query: {id: id}})
                window.open(url.href, '_blank')
            },
            changePage(val) {
                this.page = val;
                this.getList()
            }
        },
        mounted() {
            this.getList();
        }
    }
</script>

<style scoped lang="scss">
    .cooperative-goods {

        /deep/ .el-input-group {
            width: 320px;
        }

        /deep/ .el-input-group--append .el-input__inner, /deep/ .el-input-group__append {
            border-radius: $n-r;
            border: 1px solid $m-c;
        }

        /deep/ .el-input-group__append {
            background: $m-c;
            color: white;
        }
    }

    .cooperative-goods {
        padding: 20px;

        .all-goods {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 0 10px;
        }

    }

    .cooperative-goods {
        .product {
            margin-top: 10px;
            padding: 10px;

            background: white;
            position: relative;
            border: 1px solid #eee;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .img {
                width: 100%;
                height: 157px;
                margin-bottom: 10px;
                overflow: hidden;
            }

            .img :hover {
                transform: scale(1.2);
                position: relative;
                transition: 1s;
            }

            /*.error-img /deep/ .el-image__inner{object-fit: cover;}*/
            .logo-img {

                width: 100%;
                height: 180px;
                object-fit: contain;
            }

            .name {
                margin: 2px 0 4px;
            }

            .cost_price {
                margin-top: 5px;
            }
        }
        .page{margin-top: 10px;}
    }
</style>
